import {LockOutlined, QuestionCircleOutlined, UnlockOutlined} from "@ant-design/icons";
import {Layout} from 'antd';
import React, {useContext} from 'react';
import {renderRoutes} from "react-router-config";
import {Link} from "react-router-dom";
import {AppContextContext} from "../Contexts";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {anonymousRoutesMap} from "./AnonymousRoutes";
import {HomePageContent} from "./common/ApplicationConfig";
import {IconUser} from "./common/CustomIcons";
import LoginPage from "./LoginPage";
import SelectLang from "./SelectLang";
import PackageUpload from "./PackageUpload";
import IconContext from '@ant-design/icons/lib/components/Context';

const {Header, Content, Footer} = Layout;

function AnonymousHomePage() {
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig!;
    const intlMessage = useIntlMessage("anonymous-home-page");
    const iconContext = useContext(IconContext);

    const routes = Object.keys(anonymousRoutesMap).map(key => {
        if (anonymousRoutesMap[key].path === "/") {
            switch (applicationConfig.homePageContent) {
                case HomePageContent.LOGIN:
                    anonymousRoutesMap[key].component = LoginPage;
                    break;
                case HomePageContent.UPLOAD_PACKAGE:
                default:
                    if (applicationConfig!.allowUserUpload) {
                        anonymousRoutesMap[key].component = PackageUpload;
                    } else {
                        anonymousRoutesMap[key].component = LoginPage;
                    }
            }
        }
        return anonymousRoutesMap[key];
    });

    return (
        <Layout style={{minHeight: '100vh'}} className={`anonymous${applicationConfig.closedNetwork && applicationConfig.closedNetworkIsSet ? " closed-network" : " open-network"}`}>
            <Layout>
                <Header>
                    <div className={"logo"}>
                        <Link to={anonymousRoutesMap.Welcome.path}>
                            <img src="/api/public/static-resource/logo"/>
                        </Link>
                    </div>

                    <h1 className={"app-banner manual-center"}>
                        {applicationConfig.banner}
                    </h1>

                    <div className={"header-right"}>

                        <div className={"login-big"}
                             style={{textAlign: "right", float: 'right'}}>
                            <Link to={anonymousRoutesMap.Login.path}
                                  id={"loginLink"}>{intlMessage('header.login')}</Link>
                        </div>

                        <div className={"login-small"}
                             style={{textAlign: "right", float: 'right'}}>
                            <Link to={anonymousRoutesMap.Login.path} id={"loginLink"}>
                                <IconUser/>
                            </Link>
                        </div>

                        <span className={"help-link"}>
                            <a href={`https://docs.sofie.cloud/${appContext.language === 'hu' ? 'en' : appContext.language}/anonymous/`} target={"_blank"} rel="noreferrer">
                                <QuestionCircleOutlined title={intlMessage('menu.help')}/>
                            </a>
                        </span>

                    </div>

                    <SelectLang/>

                    <div className={"network-type"} hidden={!applicationConfig.closedNetworkIsSet}>
                        {applicationConfig.closedNetwork ? <LockOutlined title={intlMessage("common.CLOSED_NETWORK")}/> : <UnlockOutlined title={intlMessage("common.OPEN_NETWORK")}/>}
                    </div>


                </Header>

                <Content>
                    <div>
                        {renderRoutes(routes)}
                    </div>
                </Content>

                <Footer style={{textAlign: 'center'}}>
                    <a href={"https://www.sonpo.cz"} target={"_blank"} rel="noreferrer">
                        SONPO, a.s. © 2024
                    </a>
                </Footer>
            </Layout>
        </Layout>
    )

}

export default AnonymousHomePage;
