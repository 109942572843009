import Axios, {AxiosResponse} from "axios";
import Cookies from "js-cookie";
import moment from 'moment';
import {AppContext} from "../AppContext";
import {MultiFactorAuthentication} from "../domain/MultiFactorAuthentication";
import {MultiFactorKeyType} from "../domain/MultiFactorKey";
import {User} from "../domain/User";
import LocalStorageNamespace from "./LocalStorageNamespace";
import {LocalStorageService} from "./LocalStorageService";

export class AuthService {

    constructor(private appContext: AppContext, private localStorageService: LocalStorageService) {
        //
    }

    /**
     * Authenticates user with <i>username</i> and <i>password</i>. In case of successful login, information is saved to {@link AppContext} and local storage.
     * Also saved table and form states are removed from local storage.
     *
     * @param username username
     * @param password password
     */
    public authenticate(username: string, password: string): Promise<User> {
        return Axios.post("/user/login", {username, password}).then(this.postAuthenticateCallback);
    }

    /**
     * Authenticates user with pre-authenticated MFA token stored in httpOnly cookie. In case of successful login, information is saved to {@link AppContext} and local storage.
     * Also saved table and form states are removed from local storage.
     *
     * @param multiFactorAuthentication multi-factor authentication data
     */
    public mfaAuthenticate(multiFactorAuthentication?: MultiFactorAuthentication): Promise<User> {
        return Axios.post("/user/mfa-login", {multiFactorAuthentication}).then(this.postAuthenticateCallback);
    }

    /**
     * Provede akce nutné po přihlášení uživatele (zápis do localStorage).
     */
    private postAuthenticateCallback = (response: AxiosResponse) => {
        const user = User.fromPOJO(response.data)

        this.localStorageService.setItem(LocalStorageNamespace.AuthLoggedIn, "true");
        this.localStorageService.setItem(LocalStorageNamespace.AuthUser, JSON.stringify(user));

        this.localStorageService.removeItem(LocalStorageNamespace.PackagePassword);
        this.localStorageService.removeItem(LocalStorageNamespace.TableOrderBy);
        this.localStorageService.removeItem(LocalStorageNamespace.TablePage);
        this.localStorageService.removeItem(LocalStorageNamespace.TableFilter);
        this.localStorageService.removeItem(LocalStorageNamespace.TableSearchFormValues);

        return user;
    }

    /**
     * Returns set of supported MFA types by pre-authenticated user.
     */
    public getAvailableMfaTypes(): Promise<MultiFactorKeyType[]> {
        return Axios.get("/user/mfa-login/supported-types").then(response => response.data);
    }

    public loadLoggedInUser(): Promise<User> {
        return Axios.get("/user/login/logged", {}).then((data) => {
            const user = data.data;

            this.localStorageService.setItem(LocalStorageNamespace.AuthLoggedIn, "true");
            this.localStorageService.setItem(LocalStorageNamespace.AuthUser, JSON.stringify(user));

            return User.fromPOJO(user);
        });
    }

    public logout() {
        return Axios.get("/user/logout").then(() => {
            this.localLogout();
        });
    }

    public localLogout() {
        this.appContext.user = undefined;

        this.localStorageService.setItem(LocalStorageNamespace.AuthLoggedIn, "false");
        this.localStorageService.removeItem(LocalStorageNamespace.AuthUser);
        this.localStorageService.removeItem(LocalStorageNamespace.PackagePassword);
        this.localStorageService.removeItem(LocalStorageNamespace.JwtTokenExpireAt);
    }

    /**
     * Updates JWT token lifetime in {@link AppContext} and in local storage.
     *
     * @param jwtTokenLifetime JWT token lifetime in seconds
     */
    public updateJwtTokenExpiration(jwtTokenLifetime: number) {
        const jwtTokenExpireAt = moment().add(jwtTokenLifetime, "seconds").toDate();

        this.localStorageService.setItem(LocalStorageNamespace.JwtTokenExpireAt, String(jwtTokenExpireAt.getTime()));
    }

    /**
     * Loads saved JwtTokenExpireAt information from local storage to application context.
     */
    public loadJwtTokenExpirationFromLocalStorage() {
        const jwtTokenExpireAt = this.localStorageService.getItem(LocalStorageNamespace.JwtTokenExpireAt);

        if (jwtTokenExpireAt != null) {
            return new Date(parseInt(jwtTokenExpireAt, 10));
        }
    }

    /**
     * Loads saved information about logged in user, language and JWT token expiration time from local storage.
     */
    public loadSavedInformationFromLocalStorage() {
        const user = this.getLoggedInUserFromLocalStorage();

        if (user !== null) {
            this.appContext.user = user;
        }

        const language = this.localStorageService.getItem(LocalStorageNamespace.SettingsLanguage);

        if (language != null) {
            this.appContext.language = language;
        }
    }

    /**
     * Returns logged in user from local storage.
     */
    private getLoggedInUserFromLocalStorage(): User | null {
        const loggedIn = this.localStorageService.getItem(LocalStorageNamespace.AuthLoggedIn);

        if (loggedIn === "true") {
            return User.fromPOJO(JSON.parse(this.localStorageService.getItem(LocalStorageNamespace.AuthUser)!));
        } else {
            return null;
        }
    }
}
