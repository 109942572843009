import {CloseCircleFilled, LinkOutlined, QuestionCircleOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";
import {Alert, Button, Card, Checkbox, Col, Form, Input, InputNumber, InputRef, message, Radio, Row, Select, Skeleton, Space, Tooltip,} from 'antd';
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {useForm} from "antd/lib/form/Form";
import Cookies from "js-cookie";
import {useContext, useEffect, useRef, useState} from "react";
import {AdalConfig, AuthenticationContext} from "react-adal";
import CopyToClipboard from "react-copy-to-clipboard";
import {Redirect, useHistory, useLocation} from "react-router";
import {Link} from "react-router-dom";
import {AppContextContext, ContactGroupServiceContext, ContactServiceContext, LocalStorageServiceContext, PackageServiceContext} from "../Contexts";
import {Contact} from "../domain/Contact";
import {ContactGroup} from "../domain/ContactGroup";
import {EncryptionType, Package, PackageAccessType, PackageCheckState, PackageFile, PackagePasswordType, PackageWorkflowState} from "../domain/Package";
import {Recipient} from "../domain/Recipient";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import ValidationUtils from "../service/common/ValidationUtils";
import LocalStorageNamespace from "../service/LocalStorageNamespace";
import FormatUtils from "../utils/FormatUtils";
import {anonymousRoutesMap} from "./AnonymousRoutes";
import {DocumentTitle} from "./DocumentTitle";
import PackagePasswordRequired from "./PackagePasswordRequired";
import {routesMap} from "./Routes";
import styles from "./UploadPackage.module.css";
import {DownloadTokenInfo} from "../domain/DownloadTokenInfo";

const {TextArea} = Input;

enum ModelState {
    Loading,
    Loaded,
    PasswordRequired,
    AccessDenied,
    NotFound
}


function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => {
        return fieldsError[field].errors.length > 0;
    });
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function PackageForward() {


    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig;
    const packageService = useContext(PackageServiceContext);
    const contactService = useContext(ContactServiceContext);
    const contactGroupService = useContext(ContactGroupServiceContext);
    const localStorageService = useContext(LocalStorageServiceContext);
    const location = useLocation();

    const forceUpdate = useForceUpdate();
    const intlMessage = useIntlMessage("packages-inbox-outbox");
    const history = useHistory();

    const [form] = useForm();

    const [formState, setFormState] = useState<"FORWARD_FORM" | "DONE">("FORWARD_FORM");
    const [uploading, setUploading] = useState<boolean>(false);
    const [switchPassword, setSwitchPassword] = useState<boolean>(false);
    const downloadPasswordRef = useRef<InputRef>(null);
    const [switchTtl, setSwitchTtl] = useState<boolean>(false);

    const [forwardedPackageId, setForwardedPackageId] = useState<string>();

    const [notice, setNotice] = useState<string>("");

    const [enteredInvalidPassword, setEnteredInvalidPassword] = useState<boolean>(false);
    const [serverErrorMessage, setServerErrorMessage] = useState<string>("");
    const [accessType, setAccessType] = useState<PackageAccessType | undefined>(PackageAccessType.PRIVATE);

    const [userAddressBook, setUserAddressBook] = useState<Contact[]>([]);
    const [userContactGroups, setUserContactGroups] = useState<ContactGroup[]>([]);

    const [selectedRecipients, setSelectedRecipients] = useState<any[]>([]);
    const [recipientEmailAddressBookVisible, setRecipientEmailAddressBookVisible] = useState<boolean>(false);
    const [modelState, setModelState] = useState<ModelState>(ModelState.Loading);

    const downloadTokenInfo = useRef<DownloadTokenInfo>();

    const [aPackage, setAPackage] = useState<Package>({
        workflowState: PackageWorkflowState.ACTIVE,
        checkState: PackageCheckState.UNKNOWN,
        internalRecipients: [],
        externalRecipients: [],
        fileList: [],
        deletedFileList: [],
        uncleanFileList: []
    });

    const [packageFileSelector, setPackageFileSelector] = useState<any>([]);
    const [allPackageFileSelector, setAllPackageFileSelector] = useState<any>([]);

    const [componentUnmounting, setComponentUnmounting] = useState<boolean>(false);

    const layout = {xs: 24, sm: 24, md: 24, lg: 20, xl: 16, xxl: 12};

    const title = intlMessage("package-detail.window-title");

    const matches = location.pathname.match(/\/package-forward\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i);

    const packageId = matches![1];

    useEffect(() => {
        const storedDownloadTokenInfo = localStorageService.getIndexedItem(LocalStorageNamespace.PackagePassword, packageId);

        if (storedDownloadTokenInfo != null) {
            downloadTokenInfo.current = JSON.parse(storedDownloadTokenInfo);
        } else {
            downloadTokenInfo.current = undefined;
        }

        loadContacts();
        loadPackage();

        return () => {
            setComponentUnmounting(true);
        }
    }, [])

    useEffect(() => {
        setAccessType(getAccessType());

        form.setFieldsValue({accessType: getAccessType()});
    }, [])


    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${title}`}>
            <div>
                <Skeleton loading={modelState === ModelState.Loading}>

                    {modelState === ModelState.Loaded ? renderLoaded() : ""}
                    {modelState === ModelState.AccessDenied ? renderAccessDenied() : ""}
                    {modelState === ModelState.NotFound ? renderNotFound() : ""}
                    {modelState === ModelState.PasswordRequired ?
                        <PackagePasswordRequired onSubmit={onPasswordSubmit}
                                                 enteredInvalidPassword={enteredInvalidPassword}
                                                 serverErrorMessage={serverErrorMessage}
                                                 passwordType={PackagePasswordType.Forward}/> : ""}
                </Skeleton>
            </div>
        </DocumentTitle>
    )


    function renderLoaded() {

        let content: any;
        let h1: any;
        let h3: any;
        switch (formState) {
            case "FORWARD_FORM":
                content = renderForwardForm();
                h1 = "package.package-forward";
                h3 = "package.package-forward-note";
                break;
            case "DONE":
                content = renderDone();
                h1 = "package.forward-done";
                h3 = "package.forward-done-note";
                break;
            default:
                content = "";
        }

        return (
            <DocumentTitle
                title={`${applicationConfig!.title}: ${intlMessage("package.package-forward")}`}>
                <Row>
                    <Col {...layout}>
                        <Row>
                            <Col>
                                <div>
                                    <h1>{intlMessage(h1)}</h1>
                                    <h3></h3>
                                </div>
                                {notice &&
                                    <Alert style={{marginBottom: 24}} message={notice} type="error" showIcon={true}/>
                                }
                            </Col>
                        </Row>

                    </Col>
                </Row>
                {content}
            </DocumentTitle>
        );

    }


    function getAccessType() {
        const accessType = !!appContext!.user ? applicationConfig!.packageSendDefaultOption : PackageAccessType.PRIVATE

        let perm;
        switch (accessType) {
            case PackageAccessType.INTERNAL:
                perm = UserPermission.SEND_PACKAGE__INTERNAL;
                break;
            case PackageAccessType.PRIVATE:
                perm = UserPermission.SEND_PACKAGE__PRIVATE;
                break;
            case PackageAccessType.PUBLIC:
                perm = UserPermission.SEND_PACKAGE__PUBLIC;
                break;
        }

        if (perm && !appContext?.user?.hasPermission(perm)) {
            return undefined;
        } else {
            return accessType;
        }
    }

    // nacte adresar
    function loadContacts() {
        if (appContext!.user) {
            contactService!.getSimpleList().then((contactList: Contact[]) => {
                setUserAddressBook(contactList);
            });

            contactGroupService!.getSimpleList().then((contactGroupList: ContactGroup[]) => {
                setUserContactGroups(contactGroupList);
            });
        }
    }

    function renderAccessDenied() {

        // presmerujeme na package jen kdyz je uzivatel neprihlaseny
        if (!appContext!.user) {
            Cookies.set("Load-package", window.location.pathname, {path: "/", expires: new Date(Date.now() + (60 * 1000))});
        } else {
            Cookies.remove("Load-package", {path: "/"});
        }

        if (window.location.search && window.location.search === "?autologin-adfs") {

            const adfsConfigs = applicationConfig!.adfsConfig;
            const protocol = process.env.NODE_ENV === 'development' ? "http:" : "https:";

            if (adfsConfigs && adfsConfigs[0]) {
                const adalConfig: AdalConfig = {
                    tenant: 'adfs',
                    clientId: adfsConfigs[0].clientId,
                    redirectUri: protocol + "//" + window.location.host + adfsConfigs[0].redirectUri,
                    instance: adfsConfigs[0].instance,
                    cacheLocation: 'localStorage',
                    extraQueryParameter: 'scope=openid&response_mode=form_post',
                }

                const authContext = new AuthenticationContext(adalConfig);
                authContext.login();
            }
        }

        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    {appContext!.user ?
                        <>
                            <h1 className={"package-access-problem"}>{intlMessage("package-detail.access-denied")}</h1>

                            <CloseCircleFilled style={{fontSize: "50px", opacity: 0.3}}/>
                        </>
                        :
                        <>
                            <h2 style={{marginBottom: "50px"}}
                                className={"package-access-problem"}>{intlMessage("package-detail.access-denied-login-required")}</h2>
                            <Link to={anonymousRoutesMap.Login.path}
                                  id={"loginLink"}>{intlMessage("header.login")}</Link>
                        </>
                    }

                </Col>
            </Row>
        );
    }

    function renderNotFound() {


        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    <h1 className={"package-access-problem"}>{intlMessage("package-detail.not-found")}</h1>
                    <CloseCircleFilled style={{fontSize: "50px", opacity: 0.3}}/>
                </Col>
            </Row>
        );
    }

    function renderDone() {

        const downloadUrl = applicationConfig!.baseDownloadUri + "/packages/" + forwardedPackageId;

        let packageDoneId: string;
        if (accessType === PackageAccessType.PUBLIC) {
            packageDoneId = 'package.forward_done_explain.public';
        } else {
            packageDoneId = 'package.forward_done_explain.private';
        }

        return (
            <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                 align={"middle"}>
                <Col {...layout}>
                    <Row>
                        <Col span={24}>
                            <div style={{fontSize: "16px", paddingBottom: "20px"}}>
                                <p>
                                    {intlMessage(packageDoneId)}
                                </p>
                                <Card style={{fontSize: "16px"}}>
                                    {appContext.user ?
                                        <>
                                            <a href={downloadUrl}>{downloadUrl}</a>
                                            &nbsp;
                                            <CopyToClipboard text={downloadUrl}
                                                             onCopy={() => message.info(intlMessage("package-detail.link-copied"))}>
                                                <a title={intlMessage("package-detail.copy-link-title")}
                                                   className={"copy-link"}><LinkOutlined/></a>
                                            </CopyToClipboard>
                                        </>
                                        : <>
                                            <CopyToClipboard text={downloadUrl}
                                                             onCopy={() => message.info(intlMessage("package-detail.link-copied"))}>
                                                <a title={intlMessage("package-detail.copy-link-title")}
                                                   className={"copy-link"}>{downloadUrl} <LinkOutlined/></a>
                                            </CopyToClipboard>
                                        </>}

                                </Card>
                            </div>
                        </Col>
                    </Row>

                </Col>
            </Row>
        );

    }

    function handleDone() {
        setSelectedRecipients([])

        history.push(routesMap.PackagesInbox.path);
    }

    function renderForwardForm() {


        const {getFieldsError} = form;
        const allowedDomainsRegExp = "(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";
        const emailRegExpString = "(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")";
        const filteredAddressBook: Contact[] = [...userAddressBook];
        const {Option} = Select;

        selectedRecipients.forEach(value => {
            if (!filteredAddressBook.find(item =>
                item.email === value
            )) {
                filteredAddressBook.push({email: value, name: value, group: false});
            }
        });

        userContactGroups.forEach(group => {
            filteredAddressBook.push({email: group.name!, name: undefined, group: true});
        })

        // presmerujeme na welcome page pokud neni upload/forward povolen v pravech uzivatele
        if (!appContext!.user!.hasPermission(UserPermission.SEND_PACKAGE)) {
            return <Redirect to={routesMap.Dashboard.path}/>
        }

        return (
            <Row>
                <Col {...layout}>
                    <Row>
                        <Col span={24}>
                            <Form form={form} onFinish={handleSubmit} className={"non-modal-form package-forward-form"} layout={"vertical"}>

                                <Form.Item
                                    label={intlMessage("package.label.package-request-name")}
                                    name={"name"}
                                    rules={[
                                        {max: 100}
                                    ]}
                                    initialValue={(aPackage!.name ? "Fwd: " + aPackage!.name : "Fwd: " + intlMessage("packages-list.no-name")).substring(0, 100)}>
                                    <Input type="text" name="name" maxLength={100}/>
                                </Form.Item>

                                <Form.Item id={"recipientEmails"}
                                           label={intlMessage("package.label.recipient-email")}
                                           name={"recipientEmails"}
                                           rules={[
                                               {
                                                   required: !appContext.user || (accessType === PackageAccessType.PRIVATE),
                                                   message: intlMessage("required.recipients")
                                               }, {
                                                   pattern: new RegExp("^(" + emailRegExpString + "@(" + allowedDomainsRegExp + ")[,\n;]{0,1}[ ]*)+$"),
                                                   message: intlMessage("validation.recipients-bad-format")
                                               }
                                               , {
                                                   pattern: new RegExp("^(?!(^|([\\s\\S]*[,\\n;]{1}[ ]*))(" + emailRegExpString + "@(" + allowedDomainsRegExp + "))[\\s\\S]*[,\n;]{1}[ ]*\\3)[\\s\\S]*$"),
                                                   message: intlMessage("validation.recipients-contain-duplicates")
                                               }

                                           ]}
                                           normalize={(value, prevValue, allValues) => normalizeRecipients(value, prevValue, allValues, "REGULAR")}
                                >

                                    <Select mode="tags" size={'middle'} style={{width: '100%'}} tokenSeparators={[',']}
                                            notFoundContent={null}
                                            onSearch={fetchAddressbook}
                                            open={recipientEmailAddressBookVisible}
                                            filterOption={filterRecipientEmailOption}
                                            dropdownClassName={"recipientEmailsOptions"}
                                            defaultActiveFirstOption={true}
                                            optionLabelProp={"value"}
                                            autoFocus={true}
                                    >
                                        {filteredAddressBook.map((contact: any) => (
                                            <Option key={contact.email} value={contact.email} title={contact.name ? contact.name : contact.email}>
                                                <div className={"contact-icon"}>
                                                    {contact.group ? <TeamOutlined/> : <UserOutlined/>}
                                                </div>
                                                <div className={"contact-text"}>
                                                    <span>{contact.name && <>{contact.name}<br/></>}{contact.email}</span>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={intlMessage("common.note")}
                                    name={"note"}
                                    initialValue={aPackage!.note ? intlMessage("package.forward-note", {
                                        user: (appContext!.user!.lastName ? appContext!.user!.firstName + " " + appContext!.user!.lastName : appContext!.user!.email),
                                        date: FormatUtils.formatLocalizedDateTime(aPackage!.created!)
                                    }) + "\n" + aPackage!.note : null}>
                                    <TextArea name="note" autoSize={{minRows: 1, maxRows: 6}} autoComplete={"off"}
                                              maxLength={10000}/>
                                </Form.Item>


                                {!downloadTokenInfo.current && aPackage!.encryptionType == EncryptionType.AT_REST_PASSWORD &&
                                    <Form.Item
                                        label={intlMessage("package.label.original-password")}
                                        id={"originalDownloadPassword"}
                                        name={"originalDownloadPassword"}
                                        rules={[
                                            {
                                                required: true,
                                                message: intlMessage("required.password")
                                            },
                                            {
                                                validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'CUSTOM')
                                            }
                                        ]}>
                                        <Input.Password name="originalDownloadPassword" autoComplete={"off"} maxLength={30} onChange={e => {
                                            setTimeout(forceUpdate, 100)
                                        }}/>
                                    </Form.Item>
                                }

                                <div className="ant-row ant-form-item">
                                    <div className="ant-col-24">
                                        <Checkbox
                                            data-test-id={"downloadPasswordCheckbox"}
                                            onChange={(e) => {
                                                setSwitchPassword(e.target.checked);

                                                if (e.target.checked) {
                                                    setTimeout(() => downloadPasswordRef.current?.focus(), 0);
                                                }

                                                form.resetFields(["downloadPassword", "encryptDataWithPassword"]);
                                            }}>
                                            {intlMessage("package.label.password")}
                                        </Checkbox>
                                    </div>

                                    {
                                        switchPassword &&

                                        <>
                                            {
                                                isPasswordEncryptionEnabledForUser() &&

                                                <Form.Item
                                                    name={"encryptDataWithPassword"}
                                                    className={styles['encrypt-data-with-password']}
                                                    valuePropName={"checked"}>
                                                    <Checkbox data-test-id={"encryptDataWithPassword"}>
                                                        {intlMessage("package.label.encrypt-data-with-password")}

                                                        <Tooltip title={intlMessage("package.label.encrypt-data-with-password-tooltip")} className={styles['tooltip']}>
                                                            <QuestionCircleOutlined/>
                                                        </Tooltip>
                                                    </Checkbox>
                                                </Form.Item>
                                            }

                                            <Form.Item
                                                name={"downloadPassword"}
                                                className={'download-password'}
                                                rules={[
                                                    {min: appContext.applicationConfig?.packagePasswordRequirements.minLength, message: intlMessage("validation.password-too-short", {length: appContext.applicationConfig?.packagePasswordRequirements.minLength})},
                                                    {required: switchPassword, message: intlMessage("required.password")},
                                                    {validator: serverViolationsHolder.createServerValidator('CUSTOM')}]}>
                                                <div className="ant-col-24 padding-bottom-12px">
                                                    <Input.Password autoComplete={"none"} maxLength={30} ref={downloadPasswordRef} onChange={e => {
                                                        setTimeout(forceUpdate, 100)
                                                    }}/>
                                                </div>
                                            </Form.Item>
                                        </>
                                    }
                                </div>

                                <Form.Item className={"checkbox"}>
                                    <div className="ant-row">
                                        <div className="ant-col-24">
                                            <Checkbox data-test-id={"downloadExpirationCheckbox"}
                                                      checked={switchTtl} onChange={(e) => {
                                                setSwitchTtl(e.target.checked);
                                                form.resetFields(["downloadExpiration"])
                                            }}>{intlMessage("package.label.ttl")}</Checkbox>
                                        </div>
                                        <div className="ant-col-24" hidden={!switchTtl}>
                                            <Form.Item name="downloadExpiration" noStyle initialValue={applicationConfig!.cleanExpiration} rules={[
                                                {
                                                    required: switchTtl,
                                                    message: intlMessage("required.download-expiration")
                                                }
                                            ]}>
                                                <InputNumber size={"large"}
                                                             min={applicationConfig!.minCleanExpiration}
                                                             max={applicationConfig!.maxCleanExpiration}
                                                             name="downloadExpiration" id="downloadExpiration"
                                                             autoComplete={"none"}
                                                             onChange={() => setTimeout(forceUpdate, 100)}/>
                                            </Form.Item>
                                            &nbsp;{intlMessage("common.days")}
                                        </div>
                                    </div>
                                </Form.Item>

                                {appContext.user &&
                                    <Form.Item name={"accessType"} className={"checkbox"} extra={intlMessage("package.access-type." + accessType + ".description")}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: intlMessage("required.packageAccessType")
                                                   }
                                               ]}>

                                        <Radio.Group data-test-id={"accessType"} buttonStyle="solid" onChange={(e) => {
                                            setAccessType(e.target.value);
                                            setTimeout(form.validateFields, 50);
                                            setTimeout(forceUpdate, 200);
                                        }}>
                                            {appContext.user.hasPermission(UserPermission.SEND_PACKAGE__PUBLIC) &&
                                                <Radio.Button value={PackageAccessType.PUBLIC}>{intlMessage("package.access-type.PUBLIC")}</Radio.Button>
                                            }
                                            {appContext.user.hasPermission(UserPermission.SEND_PACKAGE__INTERNAL) &&
                                                <Radio.Button value={PackageAccessType.INTERNAL}>{intlMessage("package.access-type.INTERNAL")}</Radio.Button>
                                            }
                                            {appContext.user.hasPermission(UserPermission.SEND_PACKAGE__PRIVATE) &&
                                                <Radio.Button value={PackageAccessType.PRIVATE}>{intlMessage("package.access-type.PRIVATE")}</Radio.Button>
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                }


                                <Form.Item style={{textAlign: "right", paddingTop: "10px"}}>
                                    <Button type="primary" size="large" htmlType="submit" id={""}
                                            loading={uploading}
                                            disabled={(selectedFiles() < 1) || hasErrors(getFieldsError()) || uploading}>{intlMessage("package.forward-package-btn")}</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                    <Row className={"package-forward-file-list"}>
                        <Col>
                            <Space>
                            <a onClick={() => setPackageFileSelector(allPackageFileSelector)}>{intlMessage("common.select-all")}</a>
                            <a onClick={() => setPackageFileSelector([])}>{intlMessage("common.unselect-all")}</a>
                            </Space>
                            <table className={"package-forward-file-list"}>
                                <tbody>
                                {aPackage?.fileList?.filter((packageFile: PackageFile) => !packageFile.originalFile).map((packageFile: PackageFile) =>
                                    <tr key={packageFile.id}>
                                        <td className={"package-forward-file-list-filename"}
                                            onClick={e => handlePackageFileClicked(e, packageFile.name)}>{packageFile.name}</td>
                                        <td className={"package-forward-file-list-filesize"} width={80}
                                            onClick={e => handlePackageFileClicked(e, packageFile.name)}>{FormatUtils.formatBytes(packageFile.size)}</td>
                                        <td className={"package-forward-file-list-checkbox"} width={40}>
                                            <Checkbox checked={packageFileSelector[packageFile.name]}
                                                      onChange={(e) => handlePackageFileClicked(e, packageFile.name)}/></td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }


    function isPasswordEncryptionEnabledForUser() {
        if (appContext.user) {
            return applicationConfig?.encryptionAtRestEnabled && (applicationConfig.encryptionPasswordUsersMode === 'ENABLED' || applicationConfig.encryptionPasswordUsersMode === 'PERMISSION_BASED' && appContext.user.hasPermission(UserPermission.SEND_PACKAGE));
        } else {
            return applicationConfig?.encryptionAtRestEnabled && applicationConfig.encryptionPasswordAnonymousEnabled;
        }
    }

    function handlePackageFileClicked(e: any, key: string) {

        setPackageFileSelector((prevState: any) => {
            const tmp: any = {...prevState};
            tmp[key] = !tmp[key];
            return tmp;
        });

    }

    function selectedFiles(): number {
        return Object.keys(packageFileSelector).filter(key => packageFileSelector[key]).length;
    }

    async function handleSubmit() {

        setUploading(true);

        const values = (form.getFieldsValue() as Package);
        values.passwordProtected = switchPassword;

        // password disabled
        if (!switchPassword) {
            values.downloadPassword = undefined;
        }


        values.id = aPackage!.id;
        values.fileList = [];
        aPackage!.files!.forEach(file => {
            if (packageFileSelector[file.name]) {
                values.fileList!.push(file);
            }
        });

        // recipients
        const recipients: Recipient[] = [];
        if (values.recipientEmails) {
            values.recipientEmails.toString().split(/[,\n;]/).forEach((value: string) => {
                if (value.trim()) {
                    recipients.push({email: value.trim()});
                }
            })
        }
        values.recipients = recipients;
        values.recipientEmails = undefined;
        values.downloadPasswordToken = downloadTokenInfo.current?.downloadToken;

        packageService!.packageForward(values).then((targetPackage) => {
            setForwardedPackageId(targetPackage.id);

            setFormState("DONE");
            setUploading(false);
        }, reason => {
            const violations = reason.response.data.constraintViolations;

            // forward neni povolen
            if (reason.response.data.forwardNotAllowed) {
                setNotice(intlMessage("validation." + reason.response.data.message));
                return reason;
            }

            // validace recipientu
            if (violations.recipientEmails && violations.recipientEmails.CUSTOM_JSON!) {
                const error = violations.recipientEmails!.CUSTOM_JSON;
                const jsonError = JSON.parse(error.message);

                form.setFields([{
                        name: "recipientEmails",
                        value: form.getFieldValue("recipientEmails"),
                        errors: [new Error(intlMessage("validation." + jsonError.key + ".message", {email: jsonError.message})).message]
                    }]
                );
            }

            // validace hesla pro stazeni
            if (violations.downloadPassword!) {
                form.setFields([
                    {
                        name: "downloadPassword",
                        value: form.getFieldValue("downloadPassword"),
                        errors: [new Error(violations.downloadPassword.CUSTOM.message).message]
                    }
                ]);

            }

            // heslo
            if (violations.originalDownloadPassword && violations.originalDownloadPassword.CUSTOM!) {

                form.setFields([{
                        name: "originalDownloadPassword",
                        value: form.getFieldValue("originalDownloadPassword"),
                        errors: [new Error(intlMessage(violations.originalDownloadPassword.CUSTOM.message)).message]
                    }]
                );

                return reason;
            }

            return reason;

        }).finally(() => {
            setUploading(false);
        });
    }

    function loadPackage(options?: { password?: string, rememberPassword?: boolean }): Promise<any> {
        return packageService.get(packageId, options?.password, downloadTokenInfo.current?.downloadToken).then(
            aPkg => {
                if (aPkg.files && aPkg.files.length > 0) {

                    // seradit soubory - podezrele prvni
                    const cleanFiles: PackageFile[] = [];
                    const maliciousFiles: PackageFile[] = [];

                    for (const file of aPkg.files) {
                        if (file.state === "CLEAN") {
                            cleanFiles.push(file);
                        } else {
                            maliciousFiles.push(file);
                        }
                    }

                    aPkg.files = maliciousFiles.concat(cleanFiles);

                    aPkg.fileList = [];
                    const fileNameList: any = {};

                    for (const file of aPkg.files) {
                        if (file.state === "CLEAN" && !file.deleted && !file.originalFile) {
                            aPkg.fileList.push(file);
                            fileNameList[file.name] = true;
                        }
                    }
                    setPackageFileSelector(fileNameList);
                    setAllPackageFileSelector(fileNameList);
                }

                if (aPkg.note) {
                    const tmpNote = aPkg.note.split("------");

                    aPkg.noteFrom = tmpNote[0];
                    aPkg.noteTo = tmpNote[1];
                }

                setAPackage(aPkg);
                setModelState(ModelState.Loaded);

                return aPkg;
            },
            reason => {
                if (reason.response) {
                    if (reason.response.status === 400) {
                        setModelState(ModelState.PasswordRequired);
                    } else if (reason.response.status === 404) {
                        setModelState(ModelState.NotFound);
                    } else if (reason.response.status === 403) {

                        if (modelState === ModelState.PasswordRequired) {
                            setEnteredInvalidPassword(true);
                            setServerErrorMessage(reason.response.data);

                        } else {
                            setModelState(ModelState.AccessDenied);
                            setServerErrorMessage(reason.response.data);

                        }
                    }
                }
            });
    }

    function onPasswordSubmit(password: string, rememberPassword: boolean) {
        return loadPackage({password, rememberPassword});
    }

    function filterRecipientEmailOption(inputValue: any, option: any) {
        const key: string = option.key;
        const name: string = option.props.title;
        let displayItem = false;

        // hledani podle klice
        if (key.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        // hledani podle jmena
        if (name && name.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        if (displayItem) {
            return !selectedRecipients.find(value => {
                return value.trim() === key.trim();
            });
        } else {
            return false;
        }
    }

    function fetchAddressbook(value: any) {

        if (value && value.length > 0) {
            setRecipientEmailAddressBookVisible(true);
        } else {
            setRecipientEmailAddressBookVisible(false);
        }
    }

    function normalizeRecipients(valueArray: any[], prevArray: any[], all: any, type: "REGULAR" | "BLIND") {
        FormatUtils.expandGroup(valueArray, userContactGroups);

        const tmp = FormatUtils.normalizeRecipientsFromSelect(valueArray, prevArray, null);

        if (type === "REGULAR") {
            setRecipientEmailAddressBookVisible(false);
            setSelectedRecipients(tmp ? tmp : []);
        }

        return tmp;
    }


}

export default PackageForward;
